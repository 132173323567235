import {
    addPropertyControls,
    // @ts-ignore
    getPropertyControls,
    // @ts-ignore
    useQueryData,
    ControlType,
    RenderTarget,
} from "framer"
import { cloneElement, Children } from "react"
import { motion } from "framer-motion"
import SlideShow from "https://framer.com/m/SlideShow-07du.js"

const slideshowProps = getComponentProps(SlideShow)

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 * @framerIntrinsicWidth 400
 * @framerIntrinsicHeight 200
 */
export default function CMSSlideshow(props) {
    const { startLayers, endLayers, ...otherProps } = props
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    const items = isCanvas
        ? []
        : getCollectionListItems(props.collectionList?.[0])

    let layers = []

    if (startLayers) {
        layers = layers.concat(startLayers)
    }

    if (!isCanvas) {
        for (let i = 0; i < items.length; i++) {
            const layer = cloneElement(items[i].props.children.props.children, {
                style: { width: "100%", height: "100%" },
            })

            layers.push(
                <motion.div
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {layer}
                </motion.div>
            )
        }

        if (props.randomize) {
            layers = shuffleArray(layers)
        }
    } else {
        for (let i = 0; i < props.itemAmount; i++) {
            layers.push(
                <CanvasPlaceholder
                    title="Run project to view slideshow content"
                    subtitle="Collection List content is not accessible to the slideshow component in the editor. Run your project orvisit the live website to view the slideshow with CMS content."
                />
            )
        }
    }

    if (endLayers) {
        layers = layers.concat(endLayers)
    }

    return <SlideShow {...otherProps} slots={layers} />
}

CMSSlideshow.displayName = "CMS Slideshow"

addPropertyControls(CMSSlideshow, {
    collectionList: {
        type: ControlType.ComponentInstance,
    },
    startLayers: {
        type: ControlType.Array,
        control: {
            type: ControlType.ComponentInstance,
        },
        title: "Start",
    },
    endLayers: {
        type: ControlType.Array,
        control: {
            type: ControlType.ComponentInstance,
        },
        title: "End",
    },
    randomize: {
        type: ControlType.Boolean,
        defaultValue: false,
        description: "Shuffle order of CMS items",
    },
    ...slideshowProps,
    arrowOptions: {
        ...slideshowProps.arrowOptions,
        controls: {
            ...slideshowProps.arrowOptions.controls,
            arrowPadding: {
                ...slideshowProps.arrowOptions.controls.arrowPadding,
                max: Infinity,
                min: Infinity,
            },
        },
    },
})

function getComponentProps(component) {
    const { slots, ...otherProps } = getPropertyControls(component)
    return otherProps
}

export function CanvasPlaceholder({ title, subtitle }) {
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100%",
                placeContent: "center",
                placeItems: "center",
                flexDirection: "column",
                backgroundColor: "rgba(136, 85, 255, 0.1)",
                borderRadius: 6,
                border: "1px dashed rgb(136, 85, 255)",
                color: "rgb(136, 85, 255)",
                fontSize: 12,
                padding: 24,
            }}
        >
            <p
                style={{
                    margin: 0,
                    marginBottom: 10,
                    fontWeight: 600,
                    textAlign: "center",
                }}
            >
                {title}
            </p>
            <p
                style={{
                    margin: 0,
                    opacity: 0.7,
                    maxWidth: 500,
                    lineHeight: 1.5,
                    textAlign: "center",
                }}
            >
                {subtitle}
            </p>
        </div>
    )
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
}

export function getCollectionListItems(collectionList) {
    const query =
        collectionList?.props?.query ??
        collectionList?.props?.children?.props?.query

    if (query) {
        let childrenFunction = null
        const clpc = collectionList.props?.children
        if (typeof clpc == "function") {
            childrenFunction = clpc
        } else if (typeof clpc?.props?.children == "function") {
            childrenFunction = clpc?.props?.children
        }

        const data = useQueryData(query)

        let children = []
        if (childrenFunction) {
            let clChildren = childrenFunction(data)
            if (Array.isArray(clChildren)) {
                children = clChildren
            } else if (Array.isArray(clChildren?.props?.children?.[0])) {
                children = clChildren.props.children[0]
            } else if (Array.isArray(clChildren?.props?.children)) {
                children = clChildren.props.children
            }
        }

        if (children) {
            return Children.toArray(children)
        }
    }

    return []
}
